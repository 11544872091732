import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import SEO from '../components/seo';

const SiteMap = () => {
  return(
    <>
    <Layout>
    <SEO title="BMW SITEMAP | BMW Performance Driving School"/>
      <div className='bmw-site-map'>
        <main>
          <div className='container'>
            <h3>Site Map</h3>
            <div className="line"></div>
            <div className='row multiple-subheader'>
              <div className='header-col'>
                <h5>01 - Classes</h5>
              </div>
              <div>
                <div className='special-subheader'>              
                  <Link to="/teenschool">Teen School</Link>
                </div>
                <div className='special-subheader'>
                  <Link to="/driverschool">Driver's School</Link>
                </div>
                <div>
                  <Link to="/mschool">M School</Link>
                </div>
                <div>
                  <Link to="/mschool/mdriver">M Driver’s Program</Link>
                </div>
                <div>
                  <Link to="/mschool/onedaymschool">One-Day M School</Link>
                </div>
                <div>
                  <Link to="/mschool/twodaymschool">Two-Day M School</Link>
                </div>
                <div>
                  <Link to="/mschool/advancedmschool">Advanced M School</Link>
                </div>
                <div>
                  <Link to="/mschool/m4gt4">M4 GT4 School</Link>
                </div>
                <div>
                  <Link to="/mschool/racelicenseschool">Race License School</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>02 - Experiences</h5>
              </div>
              <div>
                <div>              
                  <Link to="/experiences">Experiences</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>03 - Group Events</h5>
              </div>
              <div>
                <div>              
                  <Link to="/groupevents">Group Events</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>04 - Delivery</h5>
              </div>
              <div>
                <div>              
                  <Link to="/delivery">Delivery</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>05 - Locations</h5>
              </div>
              <div>
                <div>              
                  <Link to="/locations">Locations</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>06 - Booking</h5>
              </div>
              <div>
                <div>              
                  <Link to="https://pds.eventsbmw.com/?_ga=2.79936210.475574430.1664287308-334664269.1636725789" 
                    target="_blank" 
                    rel="noopener noreferrer">
                    Book your class
                  </Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row multiple-subheader'>
              <div className='header-col'>
                <h5>07 - FAQ and additional information</h5>
              </div>
              <div>
                <div>              
                  <Link to="/instructors">Instructors</Link>
                </div>
                <div>              
                  <Link to="/schoolfaq">School FAQ</Link>
                </div>
                <div>              
                  <Link to="/deliveryfaq">Delivery FAQ</Link>
                </div>
                <div>              
                  <Link to="/partners">Partners</Link>
                </div>
                <div>              
                  <Link to="/hotelpartners">Hotel Partners</Link>
                </div>
                <div>              
                  <Link to="/giftcards">Gift Cards</Link>
                </div>
                <div>              
                  <Link to="/press">Press Room</Link>
                </div>
                <div>              
                  <Link to="https://www.bmwusa.com/privacy-policy/index.html" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
                </div>
                <div>              
                  <Link to="/schoolfaq">Cancellation Policy</Link>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className='row'>
              <div className='header-col'>
                <h5>08 - BMW Motorsport</h5>
              </div>
              <div>
                <div>              
                  <Link to="https://www.bmwusa.com/motorsport.html" target="_blank" rel="noopener noreferrer">BMW Motorsport</Link>
                </div>
                <div>              
                  <Link to="https://www.bmwusa.com/" target="_blank" rel="noopener noreferrer">BMWUSA.com</Link>
                </div>
                <div>              
                  <Link to="https://www.bmwgroup-werke.com/spartanburg/en/plant-tours-zentrum-museum.html" target="_blank" rel="noopener noreferrer">BMW Zentrum</Link>
                </div>
              </div>
            </div>
          </div> 
        </main>  
      </div>  
      </Layout>
    </>
  )
}

export default SiteMap;